<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="onFailedAuthentication")
    ClientHeader(:content="headerContent")
    div.wrap-client-inbox
      //- span(@click="signOut") logout
      ModuleClientAccount(v-if="clientId" :clientId="clientId")
    ClientFooter
</template>

<style lang="scss" scoped>
.wrap-client-inbox {
  width: 100%;
  min-height: 100vh;
  padding-top: 48px;
}
</style>

<script>
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import ClientHeader from '@/components/shared/ClientHeader'
import ClientFooter from '@/components/shared/ClientFooter'
import ModuleClientAccount from '@/components/module/ModuleClientAccount'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    ClientHeader,
    ClientFooter,
    ModuleClientAccount
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      headerContent: {
        label: 'アカウント'
      },
      clientId: null
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/client-sign-in')
    },
    async onLoggedIn () {
      this.clientId = await db.collection('CLIENTS')
        .where('authedClientUid', '==', this.uid)
        .get()
        .then(q => {
          return q.docs[0].id
        })
    }
  }
}
</script>
