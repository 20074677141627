<template lang="pug">
  div(v-if='user').wrap-module.py60.f.fh
    div
      div.f.fc.mb12
        div.wrap-icon
          v-avatar(size="120px").mb12
            img(:src="user.photoURL")
          p.name {{user.name}}
      //- div.section.mb20
        span.label.mb8 プロフィール
        p(v-if="user.profile") {{user.profile}}
        p(v-else) プロフィールがありません。
      //- div.section.mb10
        span.label.mb8 お支払い
          v-text-field(
            label="クレジットカード"
            append-icon="mdi-card-bulleted"
            value="1234-5678-1234-5678"
            disabled)
      //- div.wrap-history.section.mb40
        span.label.mb12 購入履歴
        div(v-for="item in histories").history-item.my8
          span.name {{item.name}}
          span.information {{item.info}}
      div.wrap-logout.f.fc
        span(@click="logout") ログアウト
</template>

<style lang="scss" scoped>
.wrap-module {
  width: 100%;
  min-height: calc(100vh - 48px * 2);
  .wrap-icon {
    margin: 0 auto;
    .name {
      text-align: center;
    }
  }
  .section {
    margin: 0 auto;
    width: 90%;
    max-width: 320px;
    .label {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
    }
  }
  .wrap-history {
    margin: 0 auto;
    width: 90%;
    max-width: 320px;
    .history-item {
      span {
        display: block;
      }
      .name {
      }
      .information {
        font-size: 12px;
        color: #999;
      }
    }
  }
  .wrap-logout {
    span {
      color: #ff5252;
      font-size: 12px;
    }
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      user: null
      // histories: [
      //   {
      //     name: 'Zoomトレーニングチケット x 11枚',
      //     info: '90,000円/11回(1回無料) ・ 残り４枚'
      //   },
      //   {
      //     name: 'ASC Sprint Test x 1回',
      //     info: '2,200円/回 ・ 6/25(木) 19:30~'
      //   },
      //   {
      //     name: 'ASC 全身持久力測定 Level.1 x 1回',
      //     info: '2,200円/回 ・ 6/25(木) 19:30~'
      //   },
      //   {
      //     name: 'ASC 全身持久力測定 Level.2 x 1回',
      //     info: '7,700円/回 ・ 6/25(木) 19:30~'
      //   },
      //   {
      //     name: 'プロテインチケット x 11枚',
      //     info: '5,000円/11枚回(1枚無料) ・ 残り４枚'
      //   }
      // ]
    }
  },
  async created () {
    this.user = await db.collection('CLIENTS')
      .doc(this.clientId)
      .get()
      .then(d => {
        return d.data()
      })
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    logout () {
      this.signOut()
      setTimeout(() => {
        this.$router.push('/client-sign-in')
      }, 400)
    }
  }
}
</script>
